import * as msal from "@azure/msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-common";

import apiCall from "@/utils/api-call";

import Vue from "vue";
import store from "@/store";

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the MASL SDK. If one has already been created, it returns that instance */
export const useMSAL = (config) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        token: null,
        user: {},
        msalClient: null,
        error: null,
      };
    },
    methods: {
      /** Authenticates the user using the redirect method */
      async loginWithRedirect() {
        try {
          const loginResponse = await this.msalClient.loginRedirect();
          console.log("login call result", loginResponse);
        } catch (err) {
          if (err.name === "BrowserAuthError") {
            const handleResponse =
              await this.msalClient.handleRedirectPromise();
            console.log(handleResponse);
            window.history.pushState({}, "", "/");
          }
          console.log("login call err", err.name, err);
        }
      },
      /** Logs the user out and removes their session on the authorization server */
      logout() {
        store.dispatch("user/logout");
        this.msalClient.logoutRedirect();
      },
      async processTokenResponse(tokenResponse, wasSilent) {
        //console.log("tokenResponse", tokenResponse);
        if (tokenResponse) {
          this.accessToken = tokenResponse.accessToken;
          this.idToken = tokenResponse.idToken;
          this.authAccount = tokenResponse.account;
          this.isAuthenticated = true;
          const response = await apiCall({
            uri: "ops/personnel/userrolesandprojects",
            method: "GET",
          });
          this.bdroles = response.data.roles;
          this.projects = response.data.projects;
          // console.log("bdroles and projects", this.bdroles, this.projects);
          this.$rws.removeEventListener("message", this.userMetadataListener);
          this.$rws.addEventListener("message", this.userMetadataListener);
          store.dispatch("user/setInfo", this);
          store.dispatch("data/preLoad", this);
          //generate accessible routes map based on roles
          if (
            !store.state.permission.routes ||
            store.state.permission.routes.length === 0
          )
            store.dispatch("permission/generateRoutes", store.state.user.roles);
          // if (!wasSilent) window.history.pushState({}, "", "/");
        }
        this.loading = false;
      },
      async userMetadataListener(event) {
        if (event && event.data) {
          const data = JSON.parse(event.data);
          // console.log("Received message", data.channel);
          if (
            data.channel ===
            `user_metadata:upsert:${this.authAccount.localAccountId}`
          ) {
            // console.log("User Metadata update", data.channel, dasta.payload);
            const response = await apiCall({
              uri: "ops/personnel/userrolesandprojects",
              method: "GET",
            });
            store.dispatch("user/updateRolesAndProjects", {
              roles: response.data.roles,
              projects: response.data.filled_projects,
            });
            //generate accessible routes map based on roles
            store.dispatch("permission/generateRoutes", store.state.user.roles);
          }
        }
      },
      test() {
        console.log("hiya");
      },
      async attemptSilentRefresh() {
        //console.log("Silent refresh");
        try {
          const accounts = this.msalClient.getAllAccounts();
          if (accounts) {
            //console.log("Auth Accounts", accounts);
            this.authAccount = accounts[0];

            var silentRequest = {
              account: this.authAccount,
              forceRefresh: false,
            };

            var request = {
              loginHint: this.authAccount.username,
            };

            const self = this;
            const tokenResponse = await this.msalClient
              .acquireTokenSilent(silentRequest)
              .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                  // fallback to interaction when silent call fails
                  return self.msalClient.acquireTokenRedirect(request);
                }
              });

            await this.processTokenResponse(tokenResponse, true);
          } else {
            this.isAuthenticated = false;
          }
        } catch (e) {
          store.dispatch("user/logout");
          this.error = e;
          console.log("Auth Error", e.name, e);
        }
      },
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created() {
      // Create a new instance of the SDK client using members of the given options object
      this.msalClient = new msal.PublicClientApplication(config);

      try {
        // check for redirect handle
        const tokenResponse = await this.msalClient.handleRedirectPromise();
        if (tokenResponse !== null) {
          await this.processTokenResponse(tokenResponse);
        } else {
          const accounts = this.msalClient.getAllAccounts();
          if (accounts) {
            //console.log("Auth Accounts", accounts);
            this.authAccount = accounts[0];

            var silentRequest = {
              account: this.authAccount,
              forceRefresh: false,
            };

            var request = {
              loginHint: this.authAccount ? this.authAccount.username : null,
            };

            const self = this;
            const tokenResponse = await this.msalClient
              .acquireTokenSilent(silentRequest)
              .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                  // fallback to interaction when silent call fails
                  return self.msalClient.acquireTokenRedirect(request);
                }
              });

            await this.processTokenResponse(tokenResponse);
          } else {
            this.isAuthenticated = false;
          }
          this.loading = false;
        }
      } catch (e) {
        store.dispatch("user/logout");
        this.loading = false;
        this.error = e;
        console.log("Auth Error", e.name, e);
      }
      setInterval(this.attemptSilentRefresh, 1000 * 60 * 1); // refresh token every 1 minutes
    },
  });

  return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const MSALPlugin = {
  install(Vue, options) {
    const msal = useMSAL(options);
    Vue.prototype.$msal = msal;
    window.$msal = msal;
  },
};
