import { resetRouter } from "@/router";

const state = {
  color: "",
  id_token: "",
  access_token: "",
  name: "",
  initials: "",
  email: "",
  id: "",
  avatar: "",
  introduction: "",
  roles: [],
  projects: [],
};

const mutations = {
  SET_USER_COLOR: (state, color) => {
    state.color = color;
  },
  SET_ID_TOKEN: (state, token) => {
    state.id_token = token;
  },
  SET_ACCESS_TOKEN: (state, token) => {
    state.access_token = token;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_INITIALS: (state, initials) => {
    state.initials = initials;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PROJECTS: (state, projects) => {
    state.projects = projects;
  },
  SET_EMAIL: (state, email) => {
    state.email = email;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
};

const actions = {
  // user set info
  setInfo({ commit }, authInfo) {
    const colorMax = 200;
    const colorMin = 80;
    const userColor = `#${(
      Math.floor(Math.random() * (colorMax - colorMin)) + colorMin
    ).toString(16)}${(
      Math.floor(Math.random() * (colorMax - colorMin)) + colorMin
    ).toString(16)}${(
      Math.floor(Math.random() * (colorMax - colorMin)) + colorMin
    ).toString(16)}`;
    commit("SET_USER_COLOR", userColor);
    commit("SET_ID_TOKEN", authInfo.idToken);
    commit("SET_ACCESS_TOKEN", authInfo.accessToken);
    commit("SET_NAME", authInfo.authAccount.name);
    commit("SET_ROLES", authInfo.bdroles);
    commit("SET_PROJECTS", authInfo.projects);
    commit("SET_EMAIL", authInfo.authAccount.username);
    commit("SET_ID", authInfo.authAccount.localAccountId);
    let initials = "U";
    if (authInfo.authAccount.name) {
      const nameParts = authInfo.authAccount.name.split(" ");
      if (nameParts.length > 1) {
        initials = nameParts[0].charAt(0) + nameParts[1].charAt(0);
      } else if (nameParts.length > 0) {
        initials = nameParts[0].charAt(0);
      }
    }
    initials = initials.toUpperCase();
    commit("SET_INITIALS", initials);
  },

  updateRolesAndProjects({ commit }, { roles, projects }) {
    commit("SET_ROLES", roles);
    commit("SET_PROJECTS", projects);
  },

  // user logout
  logout({ commit }) {
    console.log("user/logout");
    commit("SET_USER_COLOR", "");
    commit("SET_ID_TOKEN", "");
    commit("SET_ACCESS_TOKEN", "");
    commit("SET_ROLES", []);
    commit("SET_PROJECTS", []);
    commit("SET_NAME", "");
    commit("SET_INITIALS", "");
    commit("SET_EMAIL", "");
    commit("SET_ID", "");
    resetRouter();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
