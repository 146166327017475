const config = () => {
  let localConfig = {};
  if (
    process.env.NODE_ENV == "development" ||
    process.env.BUILD_MODE == "web-preview-dist"
  ) {
    try {
      localConfig = require("../../env.config.local.json");
    } catch (error) {}
  }
  const envConfig = require("../../env.config.json");
  const db_containers = require("../db_containers.js").db_containers;
  const finalConfig = Object.assign(envConfig, localConfig, {
    db_containers,
  });
  return finalConfig;
};

module.exports = config();
