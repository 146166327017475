<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link
        v-if="collapse"
        key="collapse"
        class="sidebar-logo-link"
        to="/"
      >
        <img
          v-if="environment === 'prod'"
          src="@/assets/logo.png"
          class="sidebar-logo"
        />
        <img
          v-if="environment === 'sprightprod'"
          src="@/assets/logo-spright.png"
          class="sidebar-logo"
        />
        <img
          v-if="environment === 'demo'"
          src="@/assets/logo-demo.png"
          class="sidebar-logo"
        />
        <div v-if="environment === 'staging'" class="letterlogo">S</div>
        <div v-if="environment === 'dev'" class="letterlogo">D</div>
        <div v-if="environment === 'unknown'" class="letterlogo">?</div>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img
          v-if="environment === 'prod'"
          src="@/assets/logo.png"
          class="sidebar-logo"
        />
        <img
          v-if="environment === 'spright-prod'"
          src="@/assets/logo-spright.png"
          class="sidebar-logo"
        />
        <div v-if="environment === 'staging'" class="letterlogo">Staging</div>
        <div v-if="environment === 'dev'" class="letterlogo">Dev</div>
        <div v-if="environment === 'unknown'" class="letterlogo">???</div>
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: "blueDigital",
      environment: "staging",
    };
  },
  created: function () {
    if (window.location.hostname === "localhost") this.environment = "dev";
    else if (window.location.hostname === "staging-digital.blueflite.com")
      this.environment = "staging";
    else if (window.location.hostname === "demo-digital.blueflite.com")
      this.environment = "demo";
    else if (window.location.hostname === "digital.blueflite.com")
      this.environment = "prod";
    else if (window.location.hostname === "spright.digital.blueflite.com")
      this.environment = "sprightprod";
    else this.environment = "unknown";
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: $secondaryBg;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 40px;
      height: 40px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .letterlogo {
      width: 40px;
      height: 40px;
      text-align: center;
      font-size: 30px;
      padding-left: 15px;
      color: yellow;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}

.dark-theme .sidebar-logo-container {
  background: $secondaryBgDarkTheme;
}
</style>
