import Vue from "vue";
import App from "@/App.vue";
import store from "@/store";
import router from "@/router";
import envConfig from "@/utils/config";

Vue.config.productionTip = false;

import "normalize.css/normalize.css"; // A modern alternative to CSS resets
import "remixicon/fonts/remixicon.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import locale from "element-ui/lib/locale/lang/en"; // lang i18n
import "@/styles/index.scss"; // global css
// set ElementUI lang to EN
Vue.use(ElementUI, { locale });

// azure maps
import VueAzureMaps from "vue-azure-maps";
//import "vue-azure-maps/dist/vue-azure-maps.css";
Vue.use(VueAzureMaps, {
  key: envConfig.AzureMapsKey,
});

import { MSALPlugin } from "@/azureAuth";
import "@/azureAuth/authGuard"; // permission control
Vue.use(MSALPlugin, {
  auth: {
    clientId: envConfig.AzureAuthClientId,
    authority: `https://login.microsoftonline.com/${envConfig.AzureAuthTenantId}`,
    redirectUri: envConfig.AuthRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
  },
});

// realtime websocket
import { RealtimeLayerPlugin } from "./realtimeData/realtime-layer.js";
Vue.use(RealtimeLayerPlugin, {
  urlBase: envConfig.RealtimeUrl,
  store: store,
});

// ERROR HANDLING
import apiCall from "@/utils/api-call";
Vue.config.errorHandler = (err, vm, info) => {
  console.log("Error Handler err", err);
  console.log("Error Handler vm", vm);
  console.log("Error Handler info", info);
  try {
    apiCall({
      uri: "ops/errorlogs",
      method: "POST",
      payload: {
        type: "WEB",
        message: err.message ?? "",
        stacktrace: err.stack,
        url: window.location.href,
        user:
          vm &&
          vm.$store &&
          vm.$store.state &&
          vm.$store.state.user &&
          vm.$store.state.user.email,
        version: vm && vm.$store && vm.$store.state && vm.$store.state.version,
        device:
          vm &&
          vm.$store &&
          vm.$store.state &&
          vm.$store.state.app &&
          vm.$store.state.app.device,
      },
    });
  } catch (err2) {
    console.log("Unable to log error", err2);
  }
};
window.onerror = function (message, source /*, lineno, colno, error*/) {
  try {
    if (message !== "ResizeObserver loop limit exceeded") {
      apiCall({
        uri: "ops/errorlogs",
        method: "POST",
        payload: {
          type: "WEB2",
          message: message,
          stacktrace: source,
        },
      });
    }
  } catch (err2) {
    console.log("Unable to log error", err2);
  }
};

import checkPermission from "@/utils/permission";
Vue.mixin({
  data() {
    this.$storageAccount = envConfig.AzureStorageName;
    return {
      TTTableFilterText: null,
      TTTableSortField: null,
      TTTableClickedHeader: null,
    };
  },
  methods: {
    $checkPermission: checkPermission,
    $cc: function (obj) {
      if (obj === null || obj === undefined) return obj;
      if (typeof obj === "string") return obj;
      return JSON.parse(JSON.stringify(obj));
    },
    $d(message, ...args) {
      const cleaned = args.map((arg) => {
        return this.$cc(arg);
      });
      if (message) console.log(message, ...cleaned);
      else console.log(...cleaned);
    },
    async $getAzureBlob(url) {
      return new Promise((resolve, reject) => {
        fetch(url)
          .then((response) => response.arrayBuffer())
          .then((buffer) => {
            // this.$d(buffer);
            resolve(buffer);
          })
          .catch((err) => {
            console.error(err);
            reject(err);
          });
      });
    },
    $buildItemAlertText(alert) {
      const inventoryItem = this.rt_inventory_items.find(
        (c) => c.id === alert.inventory_item_id
      );
      if (alert.type === "disposed_component") {
        return `${inventoryItem.full_serial} is marked disposed`;
      } else if (alert.type === "review") {
        if (inventoryItem) {
          if (alert.criteria_id === "__default__") {
            return `${inventoryItem.full_serial} (${this.$dv(
              inventoryItem,
              "lot.part_number_revision.part_number.inventory_category.name"
            )}${
              inventoryItem.notes ? " - " + inventoryItem.notes : ""
            }): Review ${alert.severity}${
              alert.notes ? " (" + alert.notes + ")" : ""
            }`;
          } else {
            const criteria = alert.criteria_set.criteria.find(
              (c) => c.id === alert.criteria_id
            );
            return `${inventoryItem.full_serial} (${this.$dv(
              inventoryItem,
              "lot.part_number_revision.part_number.inventory_category.name"
            )}${inventoryItem.notes ? " - " + inventoryItem.notes : ""}): ${
              criteria.description
            } - ${
              alert.severity === "Failing"
                ? criteria.failing
                : alert.severity === "Warning"
                ? criteria.warning
                : "E3: Unknown criteria state"
            }${alert.notes ? " (" + alert.notes + ")" : ""}`;
          }
        } else {
          return "E2: Unknown inventory item";
        }
      } else {
        return "E1: Unknown alert type";
      }
    },
    $buildVehicleAlertText(alert) {
      if (alert.type === "review") {
        if (alert.severity === "Failing") {
          return "Vehicle has failing component";
        } else if (alert.severity === "Warning") {
          return "Vehicle has warning component";
        } else {
          return "E3: Unknown criteria state";
        }
      } else if (alert.type === "disposed_component") {
        return "Vehicle has disposed component";
      } else {
        return "E1: Unknown alert type";
      }
    },
    $tableSortChanged(sortField) {
      this.TTTableSortField = sortField;
      this.TTTableClickedHeader = null;
    },
    $tableFilterChanged(newFilter) {
      this.TTTableFilterText = newFilter;
    },
    $tableHeaderClick(column) {
      this.TTTableClickedHeader = column;
    },
    $tableAddToFilter(text) {
      if (this.TTTableFilterText && !this.TTTableFilterText.includes(text)) {
        this.TTTableFilterText += " && " + text;
      } else {
        this.TTTableFilterText = text;
      }
    },
  },
});

new Vue({
  el: "#app",
  store,
  router,
  beforeCreate() {
    const apiHandler = require("@/utils/web-api-handler");
    window.$api = apiHandler;

    // init store
    this.$store.commit("initialiseStore");
    if (this.$store.state.settings.useDarkTheme) {
      document.body.className = "dark-theme";
    } else {
      document.body.className = "light-theme";
    }
  },
  render: (h) => h(App),
});

if (!("toJSON" in Error.prototype))
  Object.defineProperty(Error.prototype, "toJSON", {
    value: function () {
      var alt = {};

      Object.getOwnPropertyNames(this).forEach(function (key) {
        alt[key] = this[key];
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });
