import Vue from "vue";
const envConfig = require("@/utils/config");

const state = {
  containers: {},
  preLoadStarted: false,
  preLoaded: false,
};

for (const container of Object.keys(envConfig.db_containers)) {
  if (envConfig.db_containers[container].is_preload) {
    // console.log(
    //   "adding preload container state holder",
    //   envConfig.db_containers[container].container_name
    // );
    state.containers[envConfig.db_containers[container].container_name] = [];
  }
}

const mutations = {
  SET_DATA_ELEMENT: (state, { containerName, element }) => {
    // console.log("SET_DATA_ELEMENT", containerName, state.containers);
    const index = state.containers[containerName].findIndex(
      (item) => item.id === element.id
    );
    if (index === -1) {
      state.containers[containerName].push(element);
    } else {
      Vue.set(state.containers[containerName], index, element);
    }
  },
  REMOVE_DATA_ELEMENT: (state, { containerName, id }) => {
    const index = state.containers[containerName].findIndex(
      (item) => item.id === id
    );
    if (index !== -1) {
      state.containers[containerName].splice(index, 1);
    }
  },
  SET_DATA: (state, { containerName, containerData }) => {
    Vue.set(state.containers, containerName, containerData);
  },
  SET_PRELOADED: (state, value) => {
    state.preLoaded = value;
  },
  SET_PRELOADSTARTED: (state, value) => {
    state.preLoadStarted = value;
  },
};

const actions = {
  preLoad({ commit, state }) {
    if (!state.preLoadStarted) {
      // console.log("starting preLoad");
      commit("SET_PRELOADSTARTED", true);
      $api.call(
        {
          uri: "ops/datasync/preload",
          method: "GET",
        },
        (response) => {
          // console.log("preload response", response);
          const data = response.data;
          console.log("preLoadComplete", JSON.parse(JSON.stringify(data)));
          for (const containerName in data) {
            if (data.hasOwnProperty(containerName)) {
              const containerData = data[containerName];
              commit("SET_DATA", { containerName, containerData });
            }
          }
          commit("SET_PRELOADED", true);
        }
      );
    }
  },
  preLoadComplete({ commit }, data) {
    console.log("preLoadComplete", JSON.parse(JSON.stringify(data)));
    for (const containerName in data) {
      if (data.hasOwnProperty(containerName)) {
        const containerData = data[containerName];
        commit("SET_DATA", { containerName, containerData });
      }
    }
    commit("SET_PRELOADED", true);
  },
  removeElement({ commit }, { containerName, id }) {
    commit("REMOVE_DATA_ELEMENT", { containerName, id });
  },
  updateElement({ commit }, { containerName, element }) {
    console.log(
      "updateElement",
      containerName,
      JSON.parse(JSON.stringify(element))
    );
    commit("SET_DATA_ELEMENT", { containerName, element });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
