import store from "@/store";
import envConfig from "@/utils/config";

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermission(requiredRoles) {
  if (
    requiredRoles &&
    requiredRoles instanceof Array &&
    requiredRoles.length > 0
  ) {
    const userRoles = store.getters && store.getters.user_roles;
    if (userRoles.includes("Sysadmin")) return true;
    const hasPermission = userRoles.some((role) => {
      return requiredRoles.includes(role);
    });
    return hasPermission;
  } else {
    console.error(`need roles! Like checkPermission(['admin','editor'])"`);
    return false;
  }
}
