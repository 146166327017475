<template>
  <div :class="classObj" class="app-wrapper">
    <div
      v-if="device === 'mobile' && sidebar.opened"
      class="drawer-bg noprint"
      @click="handleClickOutside"
    />
    <sidebar class="sidebar-container noprint" v-show="happyConfig" />
    <div class="main-container" v-show="happyConfig">
      <div :class="{ 'fixed-header': fixedHeader }" class="noprint">
        <navbar />
      </div>
      <app-main />
    </div>

    <div v-show="!happyConfig">
      <h1>Invalid environment config</h1>
      <div>{{ JSON.stringify(envConfig) }}</div>
    </div>
  </div>
</template>

<script>
const envConfig = require("@/utils/config");
import { Navbar, Sidebar, AppMain } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";

export default {
  name: "Layout",
  components: {
    Navbar,
    Sidebar,
    AppMain,
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar;
    },
    device() {
      return this.$store.state.app.device;
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  data() {
    return {
      happyConfig: true,
      envConfig: null,
    };
  },
  created: async function () {
    // this.$d(window.location.hostname, envConfig.WebApiUrlBase);
    if (
      (window.location.hostname === "digital.blueflite.com" &&
        !(
          envConfig.WebApiUrlBase ===
            "https://global-api-prod.azurewebsites.net/v1" ||
          envConfig.WebApiUrlBase ===
            "https://prod-global-api.grayrock-14433111.centralus.azurecontainerapps.io/v1"
        )) ||
      (window.location.hostname === "staging-digital.blueflite.com" &&
        !(
          envConfig.WebApiUrlBase ===
            "https://global-api-staging.azurewebsites.net/v1" ||
          envConfig.WebApiUrlBase ===
            "https://staging-global-api.greenbeach-04fc59e4.centralus.azurecontainerapps.io/v1"
        )) ||
      (window.location.hostname === "demo-digital.blueflite.com" &&
        !(
          envConfig.WebApiUrlBase ===
            "https://global-api-demo.azurewebsites.net/v1" ||
          envConfig.WebApiUrlBase ===
            "https://demo-global-api.gentlerock-7ec09e2e.centralus.azurecontainerapps.io/v1"
        )) ||
      (window.location.hostname === "localhost" &&
        envConfig.WebApiUrlBase !== "http://localhost:3333/v1")
    ) {
      this.$d("Invalid Environment Configuration");
      this.happyConfig = false;
      this.envConfig = envConfig;
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

@media print {
  .noprint {
    display: none;
  }
}

.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
