<template>
  <div class="navbar">
    <hamburger
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu">
      <el-dropdown :show-timeout="0" trigger="click">
        <span class="el-dropdown-link">
          <el-avatar :size="40" style="background-color: #409eff">{{
            user_initials
          }}</el-avatar>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            ><el-link type="primary" @click="logout()"
              >Logout</el-link
            ></el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-popover
      placement="bottom"
      trigger="manual"
      :visible-arrow="false"
      class="noti-pop"
      v-model="isNotiOpen"
      popper-class="noti-pop"
      transition=""
    >
      <div style="max-height: 600px; overflow-y: auto">
        <div v-for="noti in notifications" :key="noti.uuid">
          <div
            style="
              display: flex;
              align-items: baseline;
              border-bottom: 1px solid #666;
            "
          >
            <div style="padding: 8px 12px">
              <div style="word-break: normal; text-align: left">
                {{ noti.message }}
              </div>
              <div style="text-align: right; color: #777">
                <small>{{ noti.timestamp | formatTimestamp }}</small>
              </div>
            </div>
            <div style="flex: 0 0 35px; align-self: center; text-align: center">
              <el-button type="text" @click="clearNoti(noti.uuid)"
                ><i class="el-icon-close"></i
              ></el-button>
            </div>
          </div>
        </div>
        <div
          v-show="!notifications.length"
          style="text-align: center; padding: 20px"
        >
          No notifications.
        </div>
      </div>

      <div v-show="notifications.length">
        <el-button
          type="primary"
          style="width: 100%; border-radius: 0"
          size="mini"
          @click="clearAllNotis"
          >Clear All Notifications</el-button
        >
      </div>
      <div
        slot="reference"
        :style="{ color: getNotiBellColor() }"
        class="noti-bell"
        @click="isNotiOpen = !isNotiOpen"
      >
        <i
          :class="{
            'el-icon-bell': notifications.length,
            'el-icon-close-notification': !notifications.length,
          }"
        ></i>
      </div>
    </el-popover>

    <div style="float: right; margin-top: 17px; margin-right: 16px">
      <el-switch
        v-model="useDarkTheme"
        inactive-icon-class="el-icon-sunny"
        active-icon-class="el-icon-moon"
      />
    </div>
  </div>
</template>

<script>
import { format as formatTz } from "date-fns-tz";
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import Hamburger from "@/components/Hamburger";

export default {
  components: {
    Breadcrumb,
    Hamburger,
  },
  data: function () {
    return {
      isNotiOpen: false,
      useDarkTheme: this.$store.state.settings.useDarkTheme,
    };
  },
  watch: {
    useDarkTheme() {
      this.$store.dispatch("settings/changeSetting", {
        key: "useDarkTheme",
        value: this.useDarkTheme,
      });
      if (this.useDarkTheme) {
        document.body.className = "dark-theme";
      } else {
        document.body.className = "light-theme";
      }
    },
  },
  computed: {
    ...mapGetters(["sidebar", "notifications", "user_name", "user_initials"]),
  },
  methods: {
    async logout() {
      await this.$msal.logout({
        returnTo: window.location.origin,
      });
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    getNotiBellColor() {
      if (this.notifications.find((n) => n.type == "error")) return "#F56C6C";
      else if (this.notifications.length) return "#a8f56c";
      else if (this.$store.state.settings.useDarkTheme) return "#fff";
      return "#333";
    },
    clearNoti(uuid) {
      if (this.notifications.length == 1) this.isNotiOpen = false;
      this.$store.dispatch("app/clearNotification", uuid);
    },
    clearAllNotis() {
      this.isNotiOpen = false;
      this.$store.dispatch("app/clearAllNotifications");
    },
  },
  filters: {
    formatTimestamp: function (value) {
      if (value) return formatTz(new Date(value), "MMM dd, HH:mm");
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.noti-bell {
  float: right;
  font-size: 26px;
  margin-top: 14px;
  margin-right: 20px;
  cursor: pointer;
}

.noti-message {
  width: 242px;
  display: inline-block;
}

.noti-clear-outer {
  width: 36px;
  position: relative;
  display: inline-block;
}

.noti-clear-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    margin-right: 20px;

    .el-avatar {
      margin-top: 5px;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #409eff;
    }
    .el-icon-arrow-down {
      font-size: 12px;
    }
    &:focus {
      outline: none;
    }
  }
}

.dark-theme {
  .navbar {
    background: $primaryBgDarkTheme;
  }
}
</style>

<style>
.noti-pop {
  padding: 0;
  width: 300px;
  border-radius: 4px;
}
</style>
