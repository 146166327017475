import { constantRoutes } from "@/router";

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (!roles) roles = [];
  if (route.meta && route.meta.roles) {
    if (
      route.meta.roles.includes("blueflite Admin") &&
      !roles.includes("blueflite Admin")
    )
      return false;
    if (roles.includes("Sysadmin")) {
      return true;
    }
    return roles.some((role) => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

/**
 * Filter routing tables by recursion
 * @param routes routes
 * @param roles
 */
export function filterRoutes(routes, roles) {
  const res = [];

  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

const state = {
  routes: [],
};

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.routes = routes;
  },
};

const actions = {
  generateRoutes({ commit }, roles) {
    const rbacRoutes = filterRoutes(constantRoutes, roles);
    // console.log("rbacRoutes", JSON.parse(JSON.stringify(rbacRoutes)));
    commit("SET_ROUTES", rbacRoutes);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
