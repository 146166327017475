// this file contains the configuration for the cosmos database containers
// IMPORTANT: an exact copy of this file should exist at global-api/src/db_container.js and ops-web-app/src/db_container.js, these files NEED TO STAY IN SYNC, so when a change is made to one, the other file should be updated as well
// containername: the name of the container in cosmos
// primary_key: the field name of the primary key for the container, most will just be the "id"
// is_preload: if true, the full container will be preloaded on page load, if false, the data will be loaded on demand
// default_sort: the field name to sort the data by for preloads
const db_containers = {
  __DATA_BACK_LINKS: {
    container_name: "__data_back_links",
    primary_key: "_pk",
    is_preload: false,
  },
  __DATA_FORWARD_LINKS: {
    container_name: "__data_forward_links",
    primary_key: "_pk",
    is_preload: false,
  },
  ALIASES: {
    container_name: "aliases",
    primary_key: "id",
    is_preload: true,
    default_sort: "display_name",
  },
  ARCHIVED_CHECKLISTS: {
    container_name: "archived_checklists_v3",
    primary_key: "id",
    is_preload: false,
  },
  ARCHIVED_FLIGHT_PLAN_RUNS: {
    container_name: "archived_flight_plan_runs_v3",
    primary_key: "id",
    is_preload: false,
  },
  ARCHIVED_FLIGHT_PLANS: {
    container_name: "archived_flight_plans_v3",
    primary_key: "id",
    is_preload: false,
  },
  ARCHIVED_LIBRARY_ITEMS: {
    container_name: "archived_library_items_v3",
    primary_key: "id",
    is_preload: false,
  },
  ARCHIVED_SOFTWARES: {
    container_name: "archived_softwares_v3",
    primary_key: "id",
    is_preload: false,
  },
  ATA_CHAPTERS: {
    container_name: "ata_chapters",
    primary_key: "id",
    is_preload: true,
    default_sort: "code",
  },
  AUDIT_LOGS: {
    container_name: "audit_logs",
    primary_key: "type",
    is_preload: false,
  },
  BDEL_FEEDBACK: {
    container_name: "bdel_feedback",
    primary_key: "_pk",
    is_preload: false,
  },
  BDEL_ITEM_IMAGES: {
    container_name: "bdel_item_images",
    primary_key: "id",
    is_preload: false,
  },
  BDEL_ITEMS: {
    container_name: "bdel_items",
    primary_key: "_pk",
    is_preload: false,
  },
  BDEL_LOCATIONS: {
    container_name: "bdel_locations",
    primary_key: "_pk",
    is_preload: false,
  },
  BDEL_NOTIFICATIONS: {
    container_name: "bdel_notifications",
    primary_key: "id",
    is_preload: false,
  },
  BDEL_ORDERS: {
    container_name: "bdel_orders",
    primary_key: "_pk",
    is_preload: false,
  },
  BDEL_STATUS: {
    container_name: "bdel_status",
    primary_key: "_pk",
    is_preload: false,
  },
  BDEL_USERS: {
    container_name: "bdel_users",
    primary_key: "_pk",
    is_preload: false,
  },
  BLFL_URLS: {
    container_name: "blfl_urls",
    primary_key: "id",
    is_preload: false,
  },
  CALENDAR_ITEMS: {
    container_name: "calendar_items",
    primary_key: "id",
    is_preload: true,
    default_sort: "_ts",
  },
  CHART_PRESETS: {
    container_name: "chart_presets",
    primary_key: "id",
    is_preload: true,
    default_sort: "name",
  },
  CHECKLISTS: {
    container_name: "checklists_v3",
    primary_key: "id",
    is_preload: true,
    default_sort: "title_text",
  },
  CLIENTS: {
    container_name: "clients",
    primary_key: "id",
    is_preload: true,
    default_sort: "name",
  },
  COMPONENT_ALERTS: {
    container_name: "inventory_item_alerts",
    primary_key: "id",
    is_preload: false,
  },
  COMPONENT_CONDITION_CRITERIA_SETS: {
    container_name: "component_condition_criteria_sets",
    primary_key: "id",
    is_preload: false,
  },
  COMPONENT_TYPES: {
    container_name: "component_types",
    primary_key: "id",
    is_preload: false,
  },
  COMPONENTS: {
    container_name: "components",
    primary_key: "id",
    is_preload: false,
  },
  CONTENT_TAGS: {
    container_name: "content_tags",
    primary_key: "id",
    is_preload: true,
    default_sort: "label",
  },
  DATA_MIGRATIONS: {
    container_name: "data_migrations",
    primary_key: "id",
    is_preload: false,
  },
  EDITABLE_CONTENT: {
    container_name: "editable_content_v3",
    primary_key: "parent_id",
    is_preload: false,
  },
  ERROR_LOGS: {
    container_name: "error_logs",
    primary_key: "type",
    is_preload: false,
  },
  EVENT_ROLES: {
    container_name: "event_roles",
    primary_key: "id",
    is_preload: true,
    default_sort: "label",
  },
  FLIGHT_LOG_PARAMETERS: {
    container_name: "flight_log_parameters",
    primary_key: "i",
    is_preload: false,
  },
  FLIGHT_LOG_TAGS: {
    container_name: "flight_log_tags",
    primary_key: "id",
    is_preload: true,
    default_sort: "name",
  },
  FLIGHT_LOGS: {
    container_name: "pypeline_flight_logs",
    primary_key: "id",
    is_preload: true,
    default_sort: "_ts",
  },
  FLIGHT_LOG_PARSED_DATA: {
    container_name: "flight_log_parsed_data",
    primary_key: "id",
    is_preload: false,
  },
  FLIGHT_PLAN_RUNS: {
    container_name: "flight_plan_runs_v3",
    primary_key: "id",
    is_preload: true,
    default_sort: "_ts",
  },
  FLIGHT_PLANS: {
    container_name: "flight_plans_v3",
    primary_key: "id",
    is_preload: true,
    default_sort: "title_text",
  },
  INVENTORY_ITEM_ALERTS: {
    container_name: "inventory_item_alerts",
    primary_key: "inventory_item_id",
    is_preload: true,
    default_sort: "_ts",
  },
  INVENTORY_CATEGORIES: {
    container_name: "inventory_categories",
    primary_key: "id",
    is_preload: true,
    default_sort: "name",
  },
  INVENTORY_CONDITION_CRITERIA_SETS: {
    container_name: "inventory_condition_criteria_sets",
    primary_key: "id",
    is_preload: true,
    default_sort: "name",
  },
  INVENTORY_ITEMS: {
    container_name: "inventory_items",
    primary_key: "id",
    is_preload: true,
    default_sort: "full_serial",
  },
  INVENTORY_LOTS: {
    container_name: "inventory_lots",
    primary_key: "part_number_revision_id",
    is_preload: true,
    default_sort: "name",
  },
  INVENTORY_PART_NUMBER_REVISIONS: {
    container_name: "inventory_part_number_revisions",
    primary_key: "part_number_id",
    is_preload: true,
    default_sort: "name",
  },
  INVENTORY_PART_NUMBERS: {
    container_name: "inventory_part_numbers",
    primary_key: "id",
    is_preload: true,
    default_sort: "name",
  },
  LIBRARY_ITEM_EDITS: {
    container_name: "library_v3_item_edits",
    primary_key: "item_id",
    is_preload: false,
  },
  LIBRARY_ITEM_REVISIONS: {
    container_name: "library_v3_item_revisions",
    primary_key: "item_id",
    is_preload: false,
  },
  LIBRARY_ITEMS: {
    container_name: "library_v3_items",
    primary_key: "id",
    is_preload: true,
    default_sort: "title_text",
  },
  LIBRARY_USER_ITEM_OPENS: {
    container_name: "library_v3_user_item_opens",
    primary_key: "user_id",
    is_preload: false,
  },
  LOCATIONS: {
    container_name: "locations",
    primary_key: "id",
    is_preload: true,
    default_sort: "label",
  },
  MISSON_PLANS: {
    container_name: "mission_plans",
    primary_key: "id",
    is_preload: false,
  },
  NEOM_PARAMS: {
    container_name: "neom_params",
    primary_key: "key",
    is_preload: true,
    default_sort: "key",
  },
  PARAM_SETS: {
    container_name: "param_sets",
    primary_key: "id",
    is_preload: true,
    default_sort: "name",
  },
  USER_QUALIFICATION_TYPES: {
    container_name: "user_qualification_types",
    primary_key: "id",
    is_preload: true,
    default_sort: "label",
  },
  PROJECTS: {
    container_name: "projects",
    primary_key: "id",
    is_preload: true,
    default_sort: "name",
  },
  SOFTWARES: {
    container_name: "softwares_v3",
    primary_key: "id",
    is_preload: true,
    default_sort: "title_text",
  },
  SPECFILES: {
    container_name: "specfiles",
    primary_key: "id",
    is_preload: true,
    default_sort: "_ts",
  },
  USER_METADATA: {
    container_name: "user_metadata",
    primary_key: "id",
    is_preload: true,
    default_sort: "first_name",
  },
  VEHICLE_ALERTS: {
    container_name: "vehicle_alerts",
    primary_key: "vehicle_id",
    is_preload: true,
    default_sort: "_ts",
  },
  VEHICLE_COMMANDER_SCRIPTS: {
    container_name: "vehicle_commander_scripts",
    primary_key: "id",
    is_preload: true,
    default_sort: "_ts",
  },
  VEHICLE_COMMANDER_REQUESTS: {
    container_name: "vehicle_commander_requests",
    primary_key: "vehicle_id",
    is_preload: false,
    default_sort: "_ts",
  },
  VEHICLE_COMMANDER_RESPONSES: {
    container_name: "vehicle_commander_responses",
    primary_key: "vehicle_id",
    is_preload: false,
    default_sort: "_ts",
  },
  VEHICLE_STATUS: {
    container_name: "vehicle_status",
    primary_key: "vehicle_id",
    is_preload: true,
    default_sort: "_ts",
  },
  VEHICLE_TELEMETRY_LATEST: {
    container_name: "vehicle_telemetry_latest",
    primary_key: "id",
    is_preload: true,
    default_sort: "_ts",
  },
  VEHICLE_TELEMETRY_HISTORY: {
    container_name: "vehicle_telemetry_history",
    primary_key: "vehicle_id",
    is_preload: false,
    default_sort: "_ts",
  },
  VEHICLE_TYPES: {
    container_name: "vehicle_types",
    primary_key: "id",
    is_preload: true,
    default_sort: "type",
  },
  VEHICLES: {
    container_name: "vehicles",
    primary_key: "id",
    is_preload: true,
    default_sort: "serial",
  },
  VENDOR_PARTS: {
    container_name: "vendor_parts",
    primary_key: "id",
    is_preload: false,
  },
  VENDORS: {
    container_name: "vendors",
    primary_key: "id",
    is_preload: true,
    default_sort: "name",
  },
};

exports.db_containers = db_containers;
