import defaultSettings from "@/settings";

const { fixedHeader, sidebarLogo, useDarkTheme, flightlogSearchOptions } =
  defaultSettings;

const state = {
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  useDarkTheme: useDarkTheme,
  flightlogSearchOptions: flightlogSearchOptions,
};

//console.log("[STATE] Settings Store Instantiated", state);

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    state[key] = value;
  },
};

const actions = {
  changeSetting({ commit }, data) {
    commit("CHANGE_SETTING", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
