const envConfig = require("@/utils/config");

const getters = {
  useDarkTheme: (state) => state.settings.useDarkTheme,
  flightlogSearchOptions: (state) => state.settings.flightlogSearchOptions,
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  user_photo: (state) => state.user.avatar,
  user_roles: (state) => state.user.roles,
  user_projects: (state) => state.user.projects,
  user_id_token: (state) => state.user.id_token,
  user_access_token: (state) => state.user.access_token,
  user_name: (state) => state.user.name,
  user_initials: (state) => state.user.initials,
  user_email: (state) => state.user.email,
  user_id: (state) => state.user.id,
  user_color: (state) => state.user.color,
  permission_routes: (state) => state.permission.routes,
  notifications: (state) => state.app.notifications,
};

export default getters;
