<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/variables.scss";
.app-main {
  /*50 = navbar  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 20px;
  background-color: #444;
}
.fixed-header + .app-main {
  padding-top: 70px;
}
.light-theme {
  .app-main {
    background-color: #eee;
  }
}
@media (max-width: 768px) {
  .fixed-header + .app-main {
    padding-top: 50px;
  }
  .app-main {
    padding: 0px;
    min-height: 100vh;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
