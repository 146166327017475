import uuid from "uuid/v1";

const _notiTimeouts = {};

const state = {
  sidebar: {
    opened: false,
    withoutAnimation: false,
  },
  device: "desktop",
  notifications: [],
};

const mutations = {
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device;
  },
  PUSH_NOTIFICATION: (state, noti) => {
    const totesNoti = Object.assign({}, noti, {
      uuid: uuid(),
    });
    // replace if tracking id is used, otherwise unshift to the top
    const trackingIndex = state.notifications.findIndex(
      (n) => totesNoti.tracking_id && n.tracking_id === totesNoti.tracking_id
    );
    if (trackingIndex > -1) {
      state.notifications.splice(trackingIndex, 1, totesNoti);
    } else {
      state.notifications.unshift(totesNoti);
    }
  },
  CLEAR_ALL_NOTIFICATIONS: (state) => {
    state.notifications.splice(0);
  },
  CLEAR_NOTIFICATION: (state, notiId) => {
    for (const [i, n] of state.notifications.entries()) {
      if (n.uuid === notiId) {
        state.notifications.splice(i, 1);
        break;
      }
    }
  },
};

const actions = {
  toggleSideBar({ commit }) {
    commit("TOGGLE_SIDEBAR");
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit("CLOSE_SIDEBAR", withoutAnimation);
  },
  toggleDevice({ commit }, device) {
    commit("TOGGLE_DEVICE", device);
  },
  pushNotification({ commit }, noti) {
    noti.timestamp = new Date();
    if (noti.tracking_id && _notiTimeouts[noti.tracking_id])
      clearTimeout(_notiTimeouts[noti.tracking_id]);
    commit("PUSH_NOTIFICATION", noti);
    if (noti.show) {
      this._vm.$notify({
        title: noti.title || "Notification",
        message: noti.message,
        type: noti.type || "info",
        offset: 50,
      });
      if (noti.timeout) {
        _notiTimeouts[noti.tracking_id] = setTimeout(() => {
          commit("PUSH_NOTIFICATION", {
            uuid: noti.uuid,
            tracking_id: noti.tracking_id,
            title: "Error",
            message: noti.timeout_message || "The operation timed out.",
            type: "error",
            timestamp: new Date(),
          });
          this._vm.$notify({
            title: "Error",
            message: noti.timeout_message,
            type: "error",
            offset: 50,
          });
        }, noti.timeout);
      }
    }
  },
  clearAllNotifications({ commit }) {
    commit("CLEAR_ALL_NOTIFICATIONS");
  },
  clearNotification({ commit, state }, notiId) {
    return new Promise((resolve) => {
      commit("CLEAR_NOTIFICATION", notiId);
      resolve([...state.notifications]);
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
