import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import package_config from "../../package.json";

const storageKey = "vuex.store";

Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context("./modules", true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

const store = new Vuex.Store({
  modules,
  getters,
  mutations: {
    initialiseStore(state) {
      // Check if the store exists
      if (localStorage.getItem(storageKey)) {
        let store = JSON.parse(localStorage.getItem(storageKey));
        // Check the version stored against current. If different, don't
        // load the cached version
        if (store && store.version == package_config.version) {
          this.replaceState(Object.assign(state, store));
        } else {
          state.version = package_config.version;
        }
      }
    },
  },
});

// if this isn't good enough, look into https://github.com/robinvdvleuten/vuex-persistedstate
// Subscribe to store updates
store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  const savedState = {
    version: package_config.version,
    app: state.app,
    settings: state.settings,
  };
  localStorage.setItem(storageKey, JSON.stringify(savedState));
});

export default store;
