const request = require("./web-api-request");

function startProgressPoller(options, callback, delay, uuids) {
  setTimeout(() => {
    request
      .get(`ops/longprocesspoll?uid=${options.uid}`)
      .then((response) => {
        console.log("poll response", response.data);
        if (
          response.data &&
          (!response.data.uuid || !uuids[response.data.uuid])
        )
          callback(response);
        if (response.data.uuid) uuids[response.data.uuid] = true;
        if (!response.data.complete)
          startProgressPoller(options, callback, delay, uuids);
      })
      .catch((error) => {
        callback(error);
      });
  }, delay);
}

const webApiHandler = {
  call: (options, callback) => {
    //console.log("API CALL", options);
    if (options.hasProgressCallback)
      startProgressPoller(
        options,
        callback,
        options.pollTimeout ? options.pollTimeout : 1000,
        {}
      );
    const config = {
      url: options.uri,
      method: options.method.toLowerCase(),
      params: { uid: options.uid },
    };
    if (options.isDownload) {
      config.responseType = "arraybuffer";
    }
    if (options.timeout) {
      config.timeout = options.timeout;
    }
    if (options.method === "GET" || options.method === "DELETE")
      config.params = Object.assign(config.params, options.payload);
    else config.data = options.payload;
    request(config)
      .then((response) => {
        if (
          response &&
          response.errorMessage &&
          response.errorMessage.startsWith("E11")
        ) {
          callback(response);
        } else if (
          response &&
          response.errorMessage &&
          response.errorMessage.startsWith("Unauthorized")
        ) {
          console.log("attempting token refresh");
          window.$msal.attemptSilentRefresh().then(() => {
            console.log("token refreshed, retrying call");
            request(config)
              .then((response) => {
                callback(response);
              })
              .catch((error) => {
                callback(error);
              });
          });
        } else {
          callback(response);
        }
      })
      .catch((error) => {
        console.log("caught error", error);
        if (error.errorMessage === "Unauthorized") {
          console.log("attempting token refresh");
          window.$msal.attemptSilentRefresh().then(() => {
            console.log("token refreshed, retrying call");
            request(config)
              .then((response) => {
                callback(response);
              })
              .catch((error) => {
                callback(error);
              });
          });
        } else {
          callback(error);
        }
      });
  },
};

module.exports = webApiHandler;
