const envConfig = require("./config");

const axios = require("axios");
const { getInstance } = require("@/azureAuth");

// create an axios instance
const service = axios.create({
  baseURL: envConfig.WebApiUrlBase,
  timeout: 180000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const auth = getInstance();
    config.headers.common["Authorization"] = `Bearer ${auth.idToken}`;
    config.headers.common["x-azure-debuginfo"] = "1";
    return config;
  },
  (error) => {
    // do something with request error
    console.log("API Request Error", error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    //console.log(response);
    if (response.data.responseError) {
      return {
        errorMessage: response.data.responseError,
      };
    } else {
      return {
        data: response.data,
        success: true,
      };
    }
  },
  (error) => {
    console.log(
      "API Response Error",
      error.response,
      error.message,
      error.name,
      error.toString()
    ); // for debug
    if (!error.response) {
      return {
        offline: true,
        errorMessage: error.message || "Unknown Error",
      };
    } else {
      if (error.response.status && error.response.status === 401) {
        return {
          errorMessage: "Unauthorized",
        };
      }
      return Promise.resolve({
        errorMessage:
          // (error.message || "Unknown Error") +
          error.response.data.errorMessage
            ? error.response.data.errorMessage
            : error.response.data.message
            ? error.response.data.message
            : "",
      });
    }
  }
);

module.exports = service;
