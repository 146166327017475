import uuid from "uuid/v1";
const apiCall = async options => {
  if (!options || !options.uri) throw new Error("Invalid API options");
  if (!options.payload) options.payload = {};
  if (!options.timeout) options.timeout = 180000;
  if (options.progress) options.hasProgressCallback = true;
  const uid = uuid();
  return new Promise((resolve, reject) => {
    // let tm = setTimeout(() => {
    //   reject(options.uri + " timed out1.");
    // }, options.timeout);
    $api.call(Object.assign(options, { uid }), response => {
      //console.log("$api.call response", options, response);
      if (options.hasProgressCallback) {
        if (response.errorMessage) {
          reject(response);
        } else if (response.complete) {
          // clearTimeout(tm);
          options.progress(response);
          resolve();
        } else {
          options.progress(response);
          // clearTimeout(tm);
          // tm = setTimeout(() => {
          //   reject(options.uri + " timed out2.");
          // }, options.timeout);
        }
      } else {
        // clearTimeout(tm);
        if (response.success) resolve(response);
        else reject(response);
      }
    });
  });
};

export default apiCall;
