import router from "../router";
import store from "../store";
import { getInstance } from "@/azureAuth";
import getPageTitle from "@/utils/get-page-title";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
NProgress.configure({ showSpinner: true }); // NProgress Configuration

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  const authService = getInstance();

  const fn = async () => {
    // if not a page requiring auth
    if (to.meta.noAuth) {
      return next();
    }

    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      if (to.meta.roles && to.meta.roles.length) {
        if (
          to.meta.roles.includes("blueflite Admin") &&
          !store.state.user.roles.includes("blueflite Admin")
        )
          return next({ path: "/404", replace: true });
        if (store.state.user.roles.includes("Sysadmin")) return next();
        const hasPermission = store.state.user.roles.some((role) => {
          return to.meta.roles.includes(role);
        });
        if (!hasPermission) {
          return next({ path: "/404", replace: true });
        }
      }

      return next();
    }
    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check our auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
