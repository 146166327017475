import uuid from "uuid/v1";
import ReconnectingWebSocket from "reconnecting-websocket";
import apiCall from "@/utils/api-call";
import envConfig from "@/utils/config";

export const RealtimeLayerPlugin = {
  install(Vue, options) {
    const urlBase = options.urlBase;
    const store = options.store;

    const clientId = uuid();

    const url = `${urlBase}${clientId}`;

    const rwsOptions = {
      // WebSocket: undefined,
      maxReconnectionDelay: 10000,
      minReconnectionDelay: 1000 + Math.random() * 4000,
      reconnectionDelayGrowFactor: 1.3,
      minUptime: 5000,
      connectionTimeout: 4000,
      maxRetries: Infinity,
      maxEnqueuedMessages: Infinity,
      startClosed: false,
      debug: false,
    };

    // console.log("Creating Realtime Socket", url);
    const rws = new ReconnectingWebSocket(url, [], rwsOptions);

    const heartbeatInterval = setInterval(() => {
      rws.send("HEARTBEAT");
    }, 30000);

    Vue.prototype.$rws = rws;
    window.$rws = rws;

    const _collator = new Intl.Collator("en", {
      numeric: true,
      sensitivity: "base",
    });

    const computedRealtimeData = {};
    for (const container of Object.keys(envConfig.db_containers)) {
      if (envConfig.db_containers[container].is_preload) {
        // console.log(
        //   "adding preload container",
        //   container,
        //   envConfig.db_containers[container]
        // );
        computedRealtimeData[`rt_${container.toLowerCase()}`] = function () {
          const data =
            store.state.data.containers[
              envConfig.db_containers[container].container_name
            ];
          data.sort((a, b) =>
            _collator.compare(
              a[envConfig.db_containers[container].default_sort],
              b[envConfig.db_containers[container].default_sort]
            )
          );
          return data;
        };
      }
    }

    // custom realtime data views
    computedRealtimeData.rtv_library_search_list = function () {
      const data = store.state.data.containers[
        envConfig.db_containers.LIBRARY_ITEMS.container_name
      ].map((item) => {
        return {
          id: item.id,
          title_text: item.title_text,
        };
      });
      data.sort((a, b) => _collator.compare(a["title"], b["title"]));
      return data;
    };

    //console.log("realtime data containers", Object.keys(computedRealtimeData));

    // build rws listeners for realtime data updates
    for (const container of Object.keys(envConfig.db_containers)) {
      if (envConfig.db_containers[container].is_preload) {
        // console.log(
        //   "adding preload listener",
        //   container,
        //   envConfig.db_containers[container]
        // );
        const containerName = envConfig.db_containers[container].container_name;
        rws.addEventListener("message", (event) => {
          if (event && event.data) {
            const data = JSON.parse(event.data);
            // on data upsert check for archived, if so remove from list, otherwise update the element
            if (data.channel.startsWith(`${containerName}:upsert:`)) {
              //console.log("Received message", data);
              if (data.payload.is_archived) {
                store.dispatch("data/removeElement", {
                  containerName: containerName,
                  id: data.payload.id,
                });
              } else {
                store.dispatch("data/updateElement", {
                  containerName: containerName,
                  element: data.payload,
                });
              }
            } else if (data.channel.startsWith(`${containerName}:delete:`)) {
              const splits = data.channel.split(":");
              const id = splits[splits.length - 1];
              store.dispatch("data/removeElement", {
                containerName: containerName,
                id: id,
              });
            }
          }
        });
      }
    }

    Vue.mixin({
      beforeDestroy() {
        if (this.messageListeners) {
          for (const messageListener of this.messageListeners) {
            rws.removeEventListener("message", messageListener);
            // console.log("Removed message listener");
          }
        }
      },
      data: function () {
        this.messageListeners = [];
        return {
          $realtimeClientId: clientId,
        };
      },
      computed: {
        dataPreLoadComplete() {
          return this.$store.state.data.preLoaded;
        },
        ...computedRealtimeData,
      },
      methods: {
        $filterByUserProjects(projectIdPath) {
          return (item) => {
            // console.log(
            //   "filter by user projects",
            //   item.project_id,
            //   this.$store.state.user.projects
            // );
            const roles = this.$store.state.user.roles;
            if (roles.includes("Sysadmin")) return true;
            const projects = this.$store.state.user.projects;
            if (!projects || !projects.length) return false;
            return projects.find((p) => p.id === this.$dv(item, projectIdPath));
          };
        },
        $dv(object, pathString) {
          const splits = pathString.split(".");
          let obj = object;
          for (const split of splits) {
            if (obj) {
              obj = obj[split];
            }
          }
          return obj;
        },
        $dva(object, pathString) {
          let obj = this.$dv(object, pathString);
          return obj && obj.length;
        },
        async $apiCall(options) {
          try {
            const response = await apiCall(options);
            return response;
          } catch (e) {
            // console.log("API Call Failed:", e);
            if (!e.errorMessage && !e.errorMessage.startsWith("ENOENT:")) {
              this.$message.error({
                message: "Error: " + e.errorMessage,
                showClose: true,
                duration: 10000,
              });
            }
            throw e;
          }
        },
        async $deleteData(containerName, id) {
          store.dispatch("data/removeElement", {
            containerName: containerName,
            id: id,
          });
          return await this.$apiCall({
            uri: `core/${containerName}/delete`,
            method: "DELETE",
            payload: { id },
          });
        },
        async $saveData(containerName, payload) {
          store.dispatch("data/updateElement", {
            containerName: containerName,
            element: payload,
          });
          return await this.$apiCall({
            uri: `core/${containerName}/upsert`,
            method: "POST",
            payload,
          });
        },
        $subscribeToRealtimeMessages(callback, filters) {
          // console.log("Subscribing to realtime messages", filter);
          const splits = filters.split("|");
          for (const filter of splits) {
            const messageListener = (event) => {
              if (event && event.data) {
                const data = JSON.parse(event.data);
                // console.log("Received message", data.channel);
                if (filter) {
                  if (filter.endsWith(":")) {
                    // console.log("Skipping message", data.channel, filter);
                    if (!data.channel.startsWith(filter)) {
                      return;
                    }
                  } else {
                    if (data.channel !== filter) {
                      // console.log("Skipping message", data.channel, filter);
                      return;
                    }
                  }
                }
                // console.log("!! Handling message", data.channel, filter);
                callback(data);
              }
            };
            rws.addEventListener("message", messageListener);
            this.messageListeners.push(messageListener);
          }
        },
        $sendRealtimeMessage(channel, payload) {
          if (!channel) {
            throw new Error("Channel is required");
          }
          if (!payload) {
            throw new Error("Payload is required");
          }
          if (rws.readyState === rws.OPEN) {
            rws.send(JSON.stringify({ uuid: uuid(), channel, payload }));
            return { success: true };
          } else {
            console.log(
              "RealtimeLayerPlugin: sendRealtimeMessage: socket not open"
            );
            return { success: false, error: "socket not open" };
          }
        },
      },
    });
  },
};
